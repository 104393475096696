<template>
  <ul class="menu-nav" style="height: calc(90vh - 420px); overflow-y: auto;" v-if="menu && activeMenu && menu.hasOwnProperty(activeMenu)">
    <template v-for="(item, index) in menu[activeMenu].children">
      <li
          v-if="activeMenu && item.children"
          :key="index"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{ 'menu-item-open': hasActiveChildren(item.link) }"
      >
        <a href="#" class="menu-link menu-toggle" v-if="item.children">
          <span v-html="getIconByKey(item.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2 mt-2'})"></span>
          <span class="menu-text">{{ $t(item.text) }}</span>
          <span class="menu-label" :class="item.id" v-if="menuState[item.id]">
          <span class="label label-rounded label-danger">{{ menuState[item.id] }}</span>
        </span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu" style="display: none; overflow: hidden;">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t(item.text) }}</span>
            </span>
            </li>
            <router-link
                v-for="(subMenu, subMenuIndex) in item.children" :key="subMenuIndex"
                v-if="!hasNestedChildren(subMenu)"
                :to="subMenu.link"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span v-html="getIconByKey(subMenu.icon, { class: 'w-25px h-25px object-cover d-inline-block mr-2 mt-2'})"></span>
                  <span class="menu-text">{{ $t(subMenu.text) }}</span>
                  <span class="menu-label" :class="subMenu.id" v-if="menuState[subMenu.id]">
                    <span class="label label-rounded label-danger">{{ menuState[subMenu.id] }}</span>
                  </span>
                </a>
              </li>
            </router-link>

            <li
                v-if="hasActiveChildren(subMenu)"
                v-for="(subMenu, subMenuIndex) in item.children" :key="subMenuIndex"
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                v-bind:class="{ 'menu-item-open': hasActiveChildren(item.menu.link) }">
              <a href="#" class="menu-link menu-toggle">
                <span v-html="getIconByKey(subMenu.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
                <span class="menu-text">{{ $t(subMenu.text) }}</span>
                <span class="menu-text">{{ $t(subMenu.text) }}</span>
                <span class="menu-label" :class="subMenu.id" v-if="menuState[subMenu.id]">
                  <span class="label label-rounded label-danger">{{ menuState[subMenu.id] }}</span>
                </span>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                  <span class="menu-link">
                    <span class="menu-text"></span>
                  </span>
                  </li>

                  <router-link
                      v-for="(nestedMenu, nestedMenuIndex) in subMenu.children" :key="nestedMenuIndex"
                      :to="nestedMenu.link"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">

                        <span v-html="getIconByKey(nestedMenu.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
                        <span class="menu-text">{{ $t(nestedMenu.text) }}</span>
                        <span class="menu-text">{{ $t(nestedMenu.text) }}</span>
                        <span class="menu-label" :class="nestedMenu.id" v-if="menuState[nestedMenu.id]">
                          <span class="label label-rounded label-danger">{{ menuState[nestedMenu.id] }}</span>
                      </span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <router-link v-if="activeMenu && !item.children" :to="item.link"
                   v-slot="{ href, navigate, isActive, isExactActive }">
        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span v-html="getIconByKey(item.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
            <span class="menu-text">{{ $t(item.text) }}</span>
            <span class="menu-label" :class="item.id" v-if="menuState[item.id]">
              <span class="label label-rounded label-danger">{{ menuState[item.id] }}</span>
            </span>
          </a>
        </li>
      </router-link>
    </template>
  </ul>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {
  BUNDLE,
  GET_SAMPLE_MENU_BADGE_NO
} from "@/core/services/store/menu.module";

export default {
  name: "KTMenu",
  computed: {
    ...mapState({
      menuState: state => state.menu[BUNDLE]
    }),
    menu() {
      return this.authUser ? this.authUser.menu : null;
    },
    activeMenu() {
      return this.$route.meta.parent ? this.$route.meta.parent : this.$route.params.parent;
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasNestedChildren(menu) {
      return menu.hasOwnProperty('children');
    }
  },
  mounted() {
    this.$store.dispatch(GET_SAMPLE_MENU_BADGE_NO);
  }
};
</script>
